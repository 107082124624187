import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.webp';
import ROUTE_PATH from '../../router/routePath';
import SidebarLink from './SidebarLink';
import { getAuthor } from '../../store/user/userActions';
import {authorSelector} from '../../store/user/userSelector';
import user from '../../assets/images/user.png';


import './style.scss';
import {USER_ROLE} from "../../helper/constant";
import { CloseOutlined, LineOutlined } from "@ant-design/icons";

const handleClickLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user_info");
  window.location.assign('/onboard');
}

const LEFT_MENU_DATA = [
  {
    routePath: ROUTE_PATH.scheduler,
    title: 'Scheduler',
    role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
    isLink: true,
  },
  {
    title: 'Ad Storm',
    role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
    items: [
      {
        routePath: ROUTE_PATH.publisher,
        title: 'Publisher',
        role: [USER_ROLE.admin, USER_ROLE.general, USER_ROLE.publisher],
        isLink: true,
      },
      {
        routePath: ROUTE_PATH.advertiser,
        title: 'Advertiser',
        role: [USER_ROLE.admin, USER_ROLE.general, USER_ROLE.advertiser],
        isLink: true,
      },
    ]
  },
  {
    routePath: ROUTE_PATH.provision,
    title: 'Provision',
    role: [USER_ROLE.admin, USER_ROLE.general, USER_ROLE.publisher],
    isLink: true,
  },
];

const RIGHT_MENU_DATA = [
  {
    title: 'CMS',
    role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
    items: [
      {
        routePath: ROUTE_PATH.vod,
        title: 'VOD',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general],
        isLink: false,
      },
      {
        routePath: ROUTE_PATH.transcoding,
        title: 'Transcoding',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
        isLink: false,
      },
      {
        routePath: ROUTE_PATH.fileManager,
        title: 'File Manager',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
        isLink: false,
      },
      {
        routePath: ROUTE_PATH.analytics,
        title: 'Analytics',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general],
        isLink: false,
      },
    ]
  },
  {
    routePath: ROUTE_PATH.bucket,
    title: 'Buckets',
    role: [USER_ROLE.admin, USER_ROLE.general],
    isLink: false,
  },
  {
    routePath: ROUTE_PATH.organization,
    title: 'Organization',
    role: [USER_ROLE.admin, USER_ROLE.general],
    isLink: true,
  },
  {
    routePath: ROUTE_PATH.users,
    title: 'Users',
    role: [USER_ROLE.admin],
    isLink: false,
  },
  {
    title: '',
    image: user,
    role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
    items: [
      {
        routePath: "",
        title: "",
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.publisher, USER_ROLE.general],
        isLink: false,
        isUserBlock: true,
      },
      {
        routePath: ROUTE_PATH.changePassword,
        title: 'Change Password',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.publisher, USER_ROLE.general],
        isLink: true,
        isUserBlock: false,
      },
      {
        routePath: ROUTE_PATH.billing,
        title: 'Billing',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.general, USER_ROLE.publisher],
        isLink: true,
      },
      {
        routePath: ROUTE_PATH.request,
        title: 'Requests',
        role: [USER_ROLE.admin, USER_ROLE.general],
        isLink: true,
      },
      {
        routePath: "",
        title: 'Sign Out',
        role: [USER_ROLE.admin, USER_ROLE.customer, USER_ROLE.publisher,USER_ROLE.general],
        isLink: false,
        isUserBlock: false,
        action: handleClickLogout,
      },
    ]
  },
];

const SideBar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const author = useSelector(authorSelector);

  const [leftMenuData, setLeftMenuData] = useState(LEFT_MENU_DATA);
  const [rightMenuData, setRightMenuData] = useState(RIGHT_MENU_DATA);

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        window.location.assign('/onboard');
      } else {
        dispatch(getAuthor());
      }
    }

    authenticate()
  }, [navigate, dispatch]);

  useEffect(() => {
    let leftMenu = [...LEFT_MENU_DATA];
    let rightMenu = [...RIGHT_MENU_DATA]
    const userRole = author?.role?.[0]?.name;
    if ( userRole !== USER_ROLE.admin) {
      if (!author?.role || author.role.length === 0) {
        leftMenu = []
        rightMenu = []
      } else {
        leftMenu = leftMenu.filter(item => {
          return undefined !== author.role.find(role => item.role.includes(role.name))
        }).map(menu => {
          if (menu?.items) {
            return {
              ...menu,
              items: menu.items.filter(item => {
                return undefined !== author.role.find(role => item.role.includes(role.name))
              })
            }
          }
          return menu
        })

        rightMenu = rightMenu.filter(item => {
          return undefined !== author.role.find(role => item.role.includes(role.name))
        }).map(menu => {
          if (menu?.items) {
            return {
              ...menu,
              items: menu.items.filter(item => {
                return undefined !== author.role.find(role => item.role.includes(role.name))
              })
            }
          }
          return menu
        })
      }
    }

    setLeftMenuData(leftMenu);
    setRightMenuData(rightMenu);
  }, [author]);

  const UserIcon = () => {
    if (rightMenuData.length === 0) {
      return null;
    }

    const { routePath, title,image, items, isLink } = rightMenuData.at(-1);
    if (isLink) {
      return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
    }
    let modifyItems = items?.map((item) => {
      if(item.isUserBlock === true) {
        item.title = (<>
          <span  className={'header-menu-item-user'}>Signed in as </span>
          <br/>
          <span className={'header-menu-item-email'}>{author.email}</span>
        </>)
      }
      return item
    })
    return (
      <SidebarLink
        to={ routePath }
        title={ title }
        image={image ?? null}
        key={ routePath }
        items={ modifyItems }
      />
    );
  }

  return (
    <div className="showfer-admin">
      {
        openMenu &&
        <div className="hidden-menu">
          {leftMenuData.map((sideBar) => {
            const { routePath, title, image, items, isLink } = sideBar;
            if (isLink) {
              return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
            }
            return (
              <SidebarLink
                to={ routePath }
                title={ title }
                image={image ?? null}
                key={ routePath }
                items={ items }
              />
            );
          })}
          {rightMenuData.map((sideBar) => {
            const { routePath, title,image, items, isLink } = sideBar;
            if (isLink) {
              return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
            }
            if (image) {
              return null;
            }
            return (
              <SidebarLink
                to={ routePath }
                title={ title }
                image={image ?? null}
                key={ routePath }
                items={ items }
              />
            );
          })}
        </div>
      }

      <div className={'header-background'} />
      <div className="header">
        {
          openMenu &&
          <div className={'header-hamburger'} onClick={() => setOpenMenu(false)}>
            <CloseOutlined />
          </div>
        }
        {
          !openMenu &&
          <div className={'header-hamburger'} onClick={() => setOpenMenu(true)}>
            <LineOutlined />
            <LineOutlined />
          </div>
        }

        <Link className="header-home-link" to={ROUTE_PATH.home}>
          <img src={logo} alt="showfer" className="header-logo" />
        </Link>
        <div className={'header-user'}>
          { UserIcon() }
        </div>

        <div className="header-menu">
          <div className="header-menu-left">

            {leftMenuData.map((sideBar) => {
              const { routePath, title, image, items, isLink } = sideBar;
              if (isLink) {
                return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
              }
              return (
                  <SidebarLink
                      to={ routePath }
                      title={ title }
                      image={image ?? null}
                      key={ routePath }
                      items={ items }
                  />
              );
            })}
          </div>
          <div className="header-menu-right">
            {rightMenuData.map((sideBar) => {
              const { routePath, title,image, items, isLink } = sideBar;
              if (isLink) {
                return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
              }
              let modifyItems = items?.map((item) => {
                if(item.isUserBlock === true) {
                  item.title = (<>
                    <span  className={'header-menu-item-user'}>Signed in as </span>
                    <br/>
                    <span className={'header-menu-item-email'}>{author.email}</span>
                  </>)
                }
                return item
              })
              return (
                  <SidebarLink
                      to={ routePath }
                      title={ title }
                      image={image ?? null}
                      key={ routePath }
                      items={ modifyItems }
                  />
              );
            })}

          </div>
        </div>
      </div>
      <div className="body">{ children }</div>
    </div>
  );
};

export default SideBar;
