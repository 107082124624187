import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import API_ROUTES from '../../apiRoutes';
import API from '../../axiosApi';

export const getAdsAll = createAsyncThunk(
  'getAdsAll',
  async ({ limit, page, buckets, statuses, fileName }) => {
    const response = await API.get(
      `${API_ROUTES.adstorm}/files?limit=${limit}&page=${page}`,
      { params: { buckets, statuses, fileName } },
    );
    return response.data;
  }
);

export const reimportItem = createAsyncThunk('reimportItem', async (item) => {
  await API.post(`${API_ROUTES.adstorm}/reimport/${item.id}`);

  return item;
})

export const changeItemPageSize = createAction(
  'changeItemPageSize',
  function prepare(pageSize) {
    return {
      payload: pageSize,
    };
  }
);

export const changeStatusFilter = createAction('changeStatusFilter', function prepare(statuses) {
  return {
    payload: statuses,
  }
})

export const changeItemPage = createAction('changeItemPage', function prepare(page) {
  return {
    payload: page,
  };
});
