const ROUTE_PATH = {
  home: '/',
  login: '/onboard',
  users: '/users',
  userCreator: '/users/create',
  userEditor: '/users/edit/:id',
  vod: '/vod',
  vodCreator: '/vod/create',
  vodEditor: '/vod/edit/:id',
  transcoding: '/transcoding',
  fileManager: '/file-manager',
  analytics: '/analytics',
  hls: '/hls',
  hlsCreator: '/hls/create',
  hlsEditor: '/hls/edit/:id',
  bucket: '/bucket',
  bucketCreate: '/bucket/create',
  bucketEdit: '/bucket/edit/:id',
  scheduler: '/scheduler',
  organization: '/organization',
  provision: '/provision',
  publisher: '/publisher',
  advertiser: '/advertiser',
  billing: '/billing',
  request: '/request',
  changePassword: '/onboard/change-password',
  notFound: '*',
};

export default ROUTE_PATH;
