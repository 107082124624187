import { configureStore } from '@reduxjs/toolkit';
import { advertisementBlockReducer } from './advertisementBlock/advertisementBlockReducer';
import { categoryReducer } from './category/categoryReducer';
import { channelReducer } from './channel/channelReducer';
import { conversionReducer } from './conversion/conversionReducer';
import { fileReducer } from './file/fileReducer';
import { fillerReducer } from './filler/fillerReducer';
import { hlsReducer } from './hls/hlsReducer';
import { roleReducer } from './role/reducer';
import { storageReducer } from './storage/storageReducer';
import { userReducer } from './user/userReducer';
import { rtmpReducer } from './rtmp/rtmpReducer';
import { mrssReducer } from "./mrss/mrssReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
import { reportReducer } from "./report/reportReducer";
import { userAmsReducer } from "./userAms/userAmsReducer";
import { channelAmsReducer } from "./channelAms/channelAmsReducer";
import { bucketReducer } from "./bucket/bucketReducer";
import { srtReducer } from "./srt/srtReducer";
import { broadcastReducer } from "./broadcast/broadcastReducer";
import {schedulerReducer} from "./scheduler/schedulerReducer";
import {adsReducer} from "./ads/adsReducer";

export const store = configureStore({
  reducer: {
    users: userReducer,
    bucket: bucketReducer,
    storage: storageReducer,
    category: categoryReducer,
    channel: channelReducer,
    conversion: conversionReducer,
    ads: adsReducer,
    roles: roleReducer,
    hls: hlsReducer,
    srt: srtReducer,
    mrss: mrssReducer,
    advertisementBlock: advertisementBlockReducer,
    file: fileReducer,
    filler: fillerReducer,
    rtmps: rtmpReducer,
    dashboard: dashboardReducer,
    report: reportReducer,
    userAms: userAmsReducer,
    channelAms: channelAmsReducer,
    broadcasts: broadcastReducer,
    scheduler: schedulerReducer,
  },
});

export default store;
