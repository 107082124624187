import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATION, LOADING_STATUS } from '../../helper/constant';
import {
  reimportItem,
  getAdsAll
} from './adsActions';
import {
  changeItemPage,
  changeItemPageSize,
  changeSelectedBuckets,
  changeStatusFilter
} from "../conversion/conversionActions";

const initialState = {
  list: [],
  loadingStatus: LOADING_STATUS.idle,
  currentPage: 1,
  pageSize: DEFAULT_PAGINATION.limit,
  statuses: [
    'processing',
    'queue',
    'success',
  ],
  totalItems: 0,
  selectedBuckets: [],
};

export const adsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAdsAll.pending, (state) => {
      state.loadingStatus = LOADING_STATUS.loading;
    })
    .addCase(getAdsAll.fulfilled, (state, action) => {
      state.loadingStatus = LOADING_STATUS.success;
      const { rows, totalItems } = action.payload.data || {};

      state.list = rows;
      state.totalItems = totalItems;
    })
    .addCase(reimportItem.fulfilled, (state, action) => {
      state.list.map((item) => {
        if (item.id === action.payload.id) {
          item.status = 'queue';
        }

        return item;
      });
    })
    .addCase(changeItemPageSize, (state, action) => {
      state.pageSize = action.payload;
    })
    .addCase(changeItemPage, (state, action) => {
      state.currentPage = action.payload;
    })
    .addCase(changeStatusFilter, (state, action) => {
      state.statuses = action.payload ?? [];
    });
});
